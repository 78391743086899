header .container {
  background-color: var(--blue);
  /* padding: 10px 30px; */
}
.logo-col {
  width: auto;
}
.right-col {
	text-align: right;
	display: flex;
	align-items: center;
}
.right-col .main_menu a {
	color: var(--white);
	padding: 18px 32px;
	margin-right: 25px;
	position: relative;
	float: left;
	font-family: "sentinel";
}
.right-col a {
	font-family: "sentinel";
}
.right-col .main_menu a:hover {
  background: var(--lightblue);
  color: var(--blue);
}
.btn-white.btn.active {
  background: var(--blue);
  color: var(--white) !important;
}
.right-col .main_menu a::after {
  display: block;
  position: absolute;
  content: "";
  border-top: 3px solid var(--blue);
  width: 1.2rem;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
}
.right-col nav {
  display: inline-block;
  /* float: left; */
}
.btn-white.btn:hover {
  background-color: var(--blue);
  color: var(--white) !important;
  border: 1px solid var(--white);
}
.logo img {
	width: 100px;
}
.right-col.mobile {
	display: none;
}

@media screen and (max-width: 1024px) {
  .container{
    width: 95%;
  }
  .logo-col {
    padding-left: 25px;
  }
  .right-col {
    display: none;
  }
  .right-col.mobile {
    display: block;
    width: 100%;
  }
  .main_menu_mobile a {
    color: var(--white);
    padding: 18px 32px;
  }
  .line {
    width: 30px;
    height: 2px;
    background: #fff;
    margin-bottom: 8px;
  }
  .m_line {
    position: absolute;
    top: 30px;
    right: 45px;
    cursor: pointer;
  }
  .m_line.active {
    top: 40px;
  }
  .main_menu_mobile {
    margin-top: 0;
    text-align: center;
    position: absolute;
    top: 100%;
    z-index: 999;
    width: 100%;
    background: var(--blue);
    padding-top: 0;
    left: 105%;
    transition: all 0.3s;
    top: 100%;
  }
  .main_menu_mobile.active {
    left: 0%;
  }
  .main_menu_mobile nav {
    padding-bottom: 25px;
    width: 100%;
  }
  .main_menu_mobile a {
    color: var(--white);
    padding: 10px 22px;
  }
  .main-col {
    padding: 20px 0;
    position: relative;
  }
  .m_line.active li:first-child {
    transform: rotate(49deg);
    position: relative;
    left: 0px;
    bottom: -2px;
  }
  .m_line.active li:nth-child(2) {
    display: none;
  }
  .m_line.active li:last-child {
    transform: rotate(-49deg);
    position: absolute;
    left: -1px;
    bottom: -2px;
  }
  
}