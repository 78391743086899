.policy-content h6{
    margin: 20px 0 10px;
}
.policy-content{
    text-align: left;
    margin-bottom: 75px;
}
.policy-content p, .policy-content li {
	margin-bottom: 12px;
	color: var(--gray);
	font-size: 16px;
}
.policy-content ul {
	padding-left: 35px;
    margin-bottom: 18px;
}
.policy-content ul li {
	list-style-type: disc;
}
.policy-content ul li:first-child {
	margin-top: 18px;
}

@media screen and (max-width: 1200px) {
	.policy-content {
		margin-bottom: 55px;
	}
	.policy-content h2, .policy-content h3 {
		font-size: 35px;
	}
}