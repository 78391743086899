.bnr-left-col {
  width: 55%;
  position: absolute;
  right: 0;
  padding: 90px 70px;
  background: #ededed;
  text-align: left;
}
.bnr-right-col {
  width: 70%;
}
.bnr-left-col h1 span {
  color: var(--white);
}
.bnr-left-col h1 {
  color: var(--blue);
  font-family: sentinel;
}
.bnr-left-col p {
  margin: 30px 0;
  font-family: "ProximaNova";
}
.bnr-main-col {
  padding: 0;
}
.bnr-right-col img {
  /* margin-left: 35px; */
  max-width: 100%;
  width: 100%;
}
#home-bnr {
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-col.bnr-main-col {
  flex-direction: row-reverse;
  justify-content: flex-end;
  position: relative;
}
#home-bnr .container {
  padding: 0;
}

@media screen and (max-width: 1024px) {
  .main-col.bnr-main-col {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
  .bnr-left-col {
    width: 100%;
    position: unset;
  }
  .bnr-right-col {
    width: 100%;
  }
  .bnr-left-col {
    padding: 15px 15px 30px;
  }
  .bnr-left-col h1 {
    font-size: 46px;
    line-height: 55px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1300px) {
  .container{
    width: 95%;
  }
  .bnr-left-col {
    padding: 30px 20px;
  }
}