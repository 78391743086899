.single-job-title-col {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.single-left-col {
  width: 60%;
  text-align: left;
}
.single-right-col {
  width: 32%;
  text-align: left;
  position: relative;
}
.single-left-col h5 {
  font-size: 28px;
  font-family: "sentinel";
  color: var(--blue);
}
.single-left-col > p {
  line-height: 32px;
  margin: 20px 0 0 0;
}
.job-info-boxes {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.box {
  text-align: center;
  background-color: #f2f4f6;
  height: 84px;
  width: 32%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.box img {
  width: 25px;
}
.box > p {
  margin-top: 2px;
  line-height: 1.3em;
  word-break: break-word;
  font-size: 14px;
}
.single-right-col p {
  line-height: 26px;
}
.single-right-col .big-font {
  font-family: "ProximaNovaBold";
  margin: 10px 0;
}
.single-right-col .btn.apply-now {
  font-size: 15px;
  margin-top: 16px;
  padding: 8px 20px;
}
.single-job-desc-col {
  margin-top: 50px;
}
.single-job-desc-col h6 {
  font-family: "ProximaNovaBold";
}
.desc-content {
  font-size: 15px;
  margin-top: 15px;
}
.desc-content p {
  line-height: 30px;
  margin-bottom: 25px;
}
.desc-content ul {
  padding-left: 30px;
}
.desc-content h2,
.desc-content h3,
.desc-content h4,
.desc-content h5,
.desc-content h6 {
  font-family: "ProximaNovaBold";
}
.desc-content ul li {
  font-size: 15px;
  list-style-type: disc;
}
.btn.more-about {
  border: 1px solid #aaaaaa;
  background-color: transparent;
  color: #000;
  padding: 8px 15px;
  margin-right: 20px;
}
.btn.apply-now-bottom {
  padding: 9px 35px;
  margin-right: 20px;
}
.bottom-btns {
  margin: 60px 0;
}
.ask-question-col > p {
  font-family: "ProximaNovaBold";
}
.single-right-col.ask-question-reuriter {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.single-right-col.ask-question-reuriter > div {
  margin-right: 20px;
}
.export-img img {
	width: 20px;
	position: absolute;
	top: -30px;
	right: -20px;
}
.ask-question-col {
  margin-bottom: 50px;
}