.job-title > p {
	font-family: "sentinel";
	font-weight: 700;
	color: var(--blue);
}
.job-information {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  justify-content: space-between;
}
.job-information .job-info_inner {
  display: flex;
  width: 30%;
  justify-content: left;
  align-items: center;
}
.job-info_inner img {
	width: 25px;
}
.job-information > div span {
  font-size: 13px;
  margin-left: 8px;
  font-family: "ProximaNova";
}
.new-job-main-col {
  background: var(--white);
  border: 1px solid #efeff0;
  padding: 50px 20px;
  margin-bottom: 30px;
  cursor: pointer;
}
.job-require-col ul li {
  font-size: 13px;
  margin-bottom: 5px;
}
.job-require-col ul li span {
  font-family: "ProximaNova";
  margin-left: 6px;
}
.company-logo {
  margin-right: 28px;
}
.job-requirment-main-col {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.new-job-main-col.active {
  border-color: var(--lightblue);
  background: var(--lightblue);
}

@media screen and (max-width: 480px) {
  .job-require-col {
    width: 76%;
  }
  .company-logo {
    margin-left: 0px!important;
  }
}

@media screen and (max-width: 767px) {
  .single-job-left-col {
    width: 100%;
  }
  .job-information {
    flex-wrap: wrap;
  }
  .job-information .job-info_inner {
    width: 100%;
    margin-bottom: 20px;
  }
  .job-requirment-main-col {
    justify-content: unset;
  }
  .company-logo {
    margin-left: 105px;
  }

}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .job-information {
    flex-wrap: wrap;
  }
  .job-information .job-info_inner {
    width: 100%;
    margin-bottom: 20px;
  }
  .job-requirment-main-col {
    justify-content: unset;
  }
  .company-logo {
    margin-left: 60px;
  }
  .job-information .job-info_inner {
    width: 33%;
  }

}