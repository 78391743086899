.App {
	text-align: center;
	min-height: 100vh;
	position: relative;
	padding-bottom: 250px;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom CSS */
@font-face {
  font-family: "sentinel";
  src: url("assets/fonts/sentinel/eeb2719d248b27841be5f4899986eedc.ttf")
    format("TrueType");
}
@font-face {
  font-family: "ProximaNovaBold";
  src: url("assets/fonts/proximanovabold/72b6f2c9990bd3d37b8013a59ed78902.ttf")
    format("TrueType");
}
@font-face {
  font-family: "ProximaNova";
  src: url("assets/fonts/proximanova/bf9f5d50c1b928ff21436517a1a95ad9.ttf")
    format("TrueType");
}
:root {
  --blue: #002554;
  --lightblue: #edf3f9;
  --white: #ffffff;
  --black: #000;
  --gray: #65686b;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  color: var(--black);
  font-family: "ProximaNova";
}
input:focus-visible {
  outline: none;
}
h1 {
  font-size: 70px;
  line-height: 80px;
  font-family: "sentinel";
  color: var(--blue);
}
h2 {
  font-size: 60px;
  font-family: "sentinel";
  color: var(--blue);
}
h3 {
  font-size: 48px;
  font-family: "sentinel";
  color: var(--blue);
}
h4 {
  font-size: 36px;
  font-family: "sentinel";
  color: var(--blue);
}
h5 {
  font-size: 30px;
  font-family: "sentinel";
  color: var(--blue);
}
h6 {
  font-size: 20px;
  font-family: "sentinel";
  color: var(--blue);
}
li,
a {
  font-size: 20px;
}
p {
  font-size: 16px;
  color: var(--gray);
  line-height: 25px;
}
p.big-font {
  font-size: 20px;
  line-height: 30px;
  color: var(--black);
}
ul,
li {
  list-style-type: none;
}
a {
  text-decoration: none;
  cursor: pointer;
  color: var(--blue);
}
.container {
  max-width: 1290px;
  width: 90%;
  margin: 0 auto;
  padding: 0 30px;
}
.container2 {
  max-width: 1500px;
  margin: 0 auto;
  width: 100%;
  padding: 0 1.5%;
}
.main-col {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.btn {
  padding: 10px 35px;
  color: var(--white);
  background-color: var(--blue);
  display: inline-block;
}
.btn-white {
  color: var(--blue) !important;
  background-color: var(--white);
  border: 1px solid var(--white);
}
.message_wrapper {
  padding: 50px 0;
  font-size: 16px;
}
.loading_img {
  width: 30px;
}
.loading_height {
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner_loading {
	position: absolute;
	height: 70vh;
	left: 0;
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
}
/* Custom CSS */
