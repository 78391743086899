.main-cookie-bnr {
	width: 100%;
	background: #002554f7;
	position: fixed;
	left: 0;
	bottom: 0;
	padding: 25px;
	z-index: 9;
}
.sub-cookie-bnr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.left-cookie {
	width: 70%;
}
.right-cookie {
	width: 30%;
}
.left-cookie p, .left-cookie p a {
	color: var(--white);
}
.btn.cookie {
	border: 2px solid var(--blue);
	margin-right: 20px;
    font-size: 16px;
    cursor: pointer;
}
.btn.cookie:hover {
	background-color: #8192a9;
}

@media screen and (max-width: 1300px) {
	.sub-cookie-bnr {
		flex-wrap: wrap;
	}
	.left-cookie {
		width: 100%;
	}
	.left-cookie p{
		font-size: 14px;
	}
	.left-cookie p a {
		font-size: 17px;
	}
	.right-cookie {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: 10px;
	}
	.btn.cookie {
		padding: 12px;
	}

}

