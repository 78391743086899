#latest-jobs-sec {
  min-height: 400px;
}
.latest-job-col {
  padding: 80px 0;
}
.title-col {
  text-align: center;
  margin-bottom: 45px;
}
.title-col h5 {
  font-family: "sentinel";
}
.job-main-col {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 30px 55px;
  border: 1px solid #efeff0;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.job-img {
  background-color: var(--blue);
  border-radius: 50%;
  width: 85px;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #338573;
  margin: auto;
}
.single-right-col .job-img {
  text-align: left;
  margin: 0;
}
.job-img img {
  width: 52%;
}
.job-info-col {
  width: 70%;
  text-align: left;
}
.job-info-col > p {
  color: var(--gray);
}
.job-info-col h6 {
  font-family: "ProximaNova";
  margin: 6px 0 8px;
}
.job-extra-info {
  display: flex;
  align-items: center;
  width: 100%;
}
.job-info-col p {
  font-family: "ProximaNova";
}
.job-extra-info img,
.job-sec-ex-info img {
  width: 20px;
  margin-right: 12px;
}
.job-sec-ex-info {
  display: flex;
  margin-left: 20px;
  align-items: center;
  justify-content: center;
}
.view-deails-col .btn {
  font-family: "ProximaNova";
  padding: 15px 22px;
}
.view-more-col {
  text-align: center;
  margin-top: 80px;
}
.view-more-col .btn {
  padding: 15px 40px;
  font-family: "ProximaNovaBold";
}

@media screen and (max-width: 1024px) {
  .latest-job-col {
    padding: 42px 0 5px;
  }
  .container {
    width: 95%;
    padding: 0;
  }
  .job-main-col {
    padding: 20px 35px;
  }
  .job-img-col {
    width: 100%;
    display: block ruby;
  }
  .job-info-col, .view-deails-col {
    width: 100%;
    text-align: center;
  }
  .job-extra-info {
    justify-content: center;
  }
  .view-deails-col .btn {
    padding: 13px 22px;
    font-size: 16px;
    margin-top: 12px;
  }
  .view-more-col {
    margin-top: 42px;
    margin-bottom: 32px;
  }
  .view-more-col .btn {
    padding: 13px 32px;
    font-size: 18px;
  }
  .job-extra-info img, .job-sec-ex-info img {
    margin-right: 4px;
  }
  .job-sec-ex-info {
    margin-left: 21px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1100px) {
  .job-info-col {
    padding-left: 12px;
  }
}