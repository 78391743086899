.about-title {
	padding: 60px 0 30px;
	position: relative;
}
.about-title::after {
	content: '';
	width: 40px;
	height: 5px;
	background: var(--blue);
	position: absolute;
	bottom: 0;
	left: 49%;
}
.about-sub-title {
	margin: 35px 0 70px;
	font-weight: normal;
}
.about-main-col1 {
	position: relative;
	display: flex;
	justify-content: flex-end;
}
.about-left-col {
	width: 50%;
	position: absolute;
	left: 0;
	padding: 110px 83px 200px;
	background: #ededed;
	text-align: left;
	top: 48px;
}
.about-right-col {
	width: 70%;
}
.about-sec-title {
	font-weight: normal;
	position: relative;
	padding: 23px 0;
}
.about-sec-title::after {
	content: '';
	width: 28px;
	height: 4px;
	background: var(--blue);
	position: absolute;
	bottom: 0;
	left: 0;
}
.about-sec-desc {
	line-height: 25px;
	margin: 23px 0;
}
.about-main-col2 {
	position: relative;
	display: flex;
	justify-content: flex-end;
    flex-direction: row-reverse;
    margin: 50px 0 100px;

}
.about-main-col2 .about-left-col {
	right: 0;
	left: auto;
}
.about-main-col2 .about-left-col {
	padding: 50px 83px 80px;
}
.btn.bnr-btn.about-sec-btn {
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 600;
	padding: 14px 45px 11px;
}
.about-sec3-desc {
	max-width: 900px;
	margin: 0 auto 80px;
}
.about-sec3-desc p {
	line-height: 28px;
	margin-bottom: 15px;
}

@media screen and (max-width: 1024px) {
	.about-title {
		padding: 40px 0 15px;
		position: relative;
		font-size: 35px;
	}
	.about-title::after {
		left: 46%;
	}
	.about-main-col1, .about-main-col2 {
		flex-direction: column-reverse;
		flex-wrap: wrap;
		margin-bottom: 25px;
	}
	.about-left-col, .about-main-col2 .about-left-col {
		width: 100%;
		position: unset;
		padding: 0px 15px 5px;
	}
	.about-right-col, .about-right-col img {
		width: 100%;
		margin: 0 auto;
	}
	.about-main-col2 .about-left-col {
		padding-bottom: 35px;
	}
	.btn.bnr-btn.about-sec-btn {
		padding: 14px 40px 11px;
	}
	.about-sub-title {
		margin: 35px 0 50px;
	}
}

@media screen and (min-width: 1025px) and (max-width: 1300px) {
	.container{
		width: 95%;
	}
	.about-left-col {
		padding: 80px 72px 75px;
	}
	.about-right-col img {
		width: 100%;
	}
	.about-main-col2 .about-left-col {
		padding: 0px 26px 32px;
	}
}
