.single-job-title-col {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.single-left-col {
  width: 60%;
  text-align: left;
}
.single-right-col {
  width: 32%;
  text-align: left;
}
.single-left-col h5 {
  font-size: 28px;
  font-family: "sentinel";
  color: var(--blue);
}
.box {
  text-align: center;
  background-color: #f2f4f6;
  height: 84px;
  width: 32%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.box img {
  width: 25px;
}
.box > p {
  margin-top: 2px;
  line-height: 1.3em;
  word-break: break-word;
  font-size: 14px;
}
.single-right-col p {
  line-height: 26px;
}
.single-right-col .big-font {
  font-family: "ProximaNovaBold";
  margin: 10px 0;
}
.btn.apply-now {
  font-size: 15px;
  margin-top: 16px;
  padding: 9px 35px;
  border: none;
  cursor: pointer;
}
.single_job_view .single-job-desc-col {
  margin-top: 50px;
  width: 60%;
  text-align: left;
}
.single-job-desc-col h6 {
  font-family: "ProximaNovaBold";
}
.single_job_view .desc-content {
  text-align: left;
}
.desc-content {
  font-size: 15px;
  margin-top: 15px;
}
.desc-content p {
  line-height: 30px;
  margin-bottom: 25px;
}
.desc-content ul {
  padding-left: 30px;
}
.desc-content h2,
.desc-content h3,
.desc-content h4,
.desc-content h5,
.desc-content h6 {
  font-family: "ProximaNovaBold";
}
.desc-content ul li {
  font-size: 15px;
  list-style-type: disc;
}
.btn.more-about {
  border: 1px solid #aaaaaa;
  background-color: transparent;
  color: #000;
  padding: 8px 15px;
  margin-right: 40px;
}
.single_job_view .bottom-btns {
  margin: 60px 0;
  width: 60%;
  text-align: left;
}
.single_job_view .ask-question-col {
	width: 60%;
	text-align: left;
	margin-bottom: 100px;
}
.ask-question-col > p {
  font-family: "ProximaNovaBold";
}
.single-right-col.ask-question-reuriter {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.single-right-col.ask-question-reuriter > div {
  margin-right: 20px;
}
.single_job_view .back_jobs {
  max-width: 1290px;
  text-align: left;
  margin: 12px auto;
}
.back-btn {
	color: var(--blue);
	margin: 20px 0;
	display: inline-block;
	font-size: 16px;
}


@media screen and (max-width: 1024px) {
  .single-job-title-col {
    flex-wrap: wrap;
  }
  .single-left-col, .single-right-col {
    width: 100%;
  }
  .single-left-col {
    margin-bottom: 32px;
  }
  .job-info-boxes {
    max-width: 460px;
  }
  .single_job_view .single-job-desc-col, .single_job_view .bottom-btns, .single_job_view .ask-question-col {
    width: 100%;
  }
  .btn.more-about {
    padding: 8px 7px;
    margin-right: 14px;
    font-size: 17px;
  }
  .single_job_view .bottom-btns {
    margin: 12px 0 35px 0;
  }
  .single_job_view .ask-question-col {
    margin-bottom: 42px;
  }
}