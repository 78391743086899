.popup {
    padding: 40px;
}
.popup h4 {
	text-align: center;
	position: relative;
	padding-bottom: 15px;
	margin-bottom: 20px;
}
.popup h4::after{
    content: '';
    width: 35px;
    height: 3px;
    background: var(--blue);
    position: absolute;
    bottom: 0;
    left: 46%;
}
#form {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.half-col {
	width: 48%;
}

.full-col {
	width: 100%;
}
label {
	width: 100%;
	display: inline-block;
    margin-bottom: 5px;
}
label span {
	color: red;
	margin-left: 5px;
}
#form input {
	width: 100%;
	padding: 8px;
	margin-bottom: 20px;
}
#form textarea {
	width: 100%;
    resize: none;
    margin-bottom: 20px;
    padding: 8px;
}
textarea:focus-visible {
	outline: none;
}
textarea::placeholder {
	font-family: ProximaNova;
	font-size: 14px;
}
#cv {
    width: 35%;
}
#form button {
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 1px;
	cursor: pointer;
	border: 1px solid var(--blue);
	font-size: 14px;
}
.message_wrapper.popup_loading {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0,0,0,0.3);
	padding: 0;
}