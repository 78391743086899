.single-job-main-col {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    padding-bottom: 50px;
  }
  .single-job-left-col {
    width: 40%;
    text-align: left;
  }
  .single-job-right-col {
    width: 58%;
    border: 1px solid #efeff0;
    padding: 50px 40px;
    text-align: left;
  }
  .pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .pagination li {
    color: #C6C6C6;
    font-size: 16px;
    padding: 10px 5px;
    cursor: pointer;
  }
  .pagination li.active{
    color: #012554;
  }

  @media screen and (max-width: 1080px) {
    .single-job-right-col {
      display: none;
    }
  }