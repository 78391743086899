#footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

#footer .container {
  padding: 20px 30px;
  background-color: var(--blue);
}

.footer-main-col p {
  text-align: center;
  font-size: 14px;
  color: var(--white);
}
.footer-menu a {
	border-right: 2px solid var(--white);
	color: var(--white);
	font-family: sentinel;
	padding: 0 26px;
	font-size: 14px;
}
.footer-menu a:last-child {
	border: none;
}
.footer-menu {
  margin: 8px 0 30px;
}
.copy-right {
  max-width: 1290px;
  margin: 0 auto;
  display: flex;
  padding: 40px 0;
}
.copy-right-left {
  width: 50%;
  text-align: left;
}
.footer_links {
  text-align: right;
  width: 50%;
}
.footer-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fooetr-copy-right .container {
	background: none !important;
  padding: 0 30px !important;
}
.copy-right p, .copy-right a {
  font-size: 12px;
}
.footer-icons img {
	margin: 10px;
	max-width: 30px;
}
.footer_links {
	display: flex;
	flex-direction: row;
	justify-content: right;
}
.footer_links a {
	padding: 4px 10px;
	border-right: 1px solid var(--gray);
	color: var(--gray);
}
.footer_links a:last-child {
	border: none;
}


@media screen and (max-width: 767px) {
  .copy-right {
    flex-wrap: wrap;
    padding: 20px 0;
  }
  .copy-right-left {
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
  }
  .footer_links {
    width: 100%;
    justify-content: center;
  }
}
