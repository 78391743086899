.filter-main-col {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  padding: 50px 0;
  padding-top: 30px;
}
.fltr-left-col {
  width: 40%;
}
.fltr-right-col {
  width: 58%;
}
.fltr-left-col {
  display: flex;
  align-items: center;
}
.dropdown select {
  padding: 15px 60px 15px 20px;
  background-color: var(--white);
  border: 1px solid #d6d6d6;
  -webkit-appearance: none;
  font-size: 20px;
  width: 100%;
  color: #000;
}
.dropdown {
  position: relative;
}
.dropdown .svg-inline--fa {
  position: absolute;
  bottom: 23px;
  right: 20px;
  font-size: 12px;
}
.dropdown.location i {
  right: 37px;
}
.dropdown-col h6 {
  font-family: "ProximaNovaBold";
  margin-bottom: 10px;
  text-align: left;
}
.dropdown-col {
  width: 50%;
}
.dropdown select,
option {
  font-size: 16px;
}
.search-bar-col input {
  width: 100%;
  padding: 15px 11px 15px 60px;
  background: #ffffff;
  border: 1px solid #d6d6d6;
  font-size: 16px;
}
.search-bar-col input::placeholder {
  color: var(--black);
  opacity: 1;
}
.search-bar-col img {
  position: absolute;
  width: 18px;
  top: 16px;
  left: 20px;
}
.dropdown.location {
  margin-left: 20px;
}
.search-bar-col {
  position: relative;
}
.search-title {
  width: 100%;
  padding: 35px 0 0;
  text-align: left;
}
.search-title h6 {
  font-family: "ProximaNovaBold";
  margin-bottom: 10px;
}



@media screen and (max-width: 767px) {
  .fltr-left-col {
    width: 100%;
    flex-wrap: wrap;
  }
  .dropdown-col, .fltr-right-col {
    width: 100%;
  }
  .dropdown.location {
    margin-left: 0;
    margin-top: 30px;
  }
  .single-job-left-col {
    width: 100%;
  }
  .job-information {
    flex-wrap: wrap;
  }
  .job-information .job-info_inner {
    width: 100%;
    margin-bottom: 20px;
  }

}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .fltr-left-col {
    width: 100%;
  }
  .fltr-right-col {
    width: 50%;
  }
  .job-information {
    flex-wrap: wrap;
  }
  .job-information .job-info_inner {
    width: 100%;
    margin-bottom: 20px;
  }

}

@media screen and (max-width: 1080px) {
  .single-job-left-col {
    width: 100%;
  }
}